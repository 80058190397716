import React from 'react'
import './index.scss'
const UnderLine = () => {
  return (
    <div className='underline'>
        <div className='underline-style'></div>
    </div>
  )
}

export default UnderLine