export const SpCreateSpareEstimateColumn =  [
    { title: "Spare Description", field: "name" },
    { title: "Quantity", field: "quantity" },
    { title: "HSN/SAC", field: "hsn_sac" },
    { title: "Price", field: "selling_price" },
    { title: "Tax %", field: "tax" },
    { title: "Tax Amount", field: "tax_amount" },
    { title: "Amount", field: "amount" },

]

// export const SpEditSpareEstimateColumn =  [
//     { title: "Spare Description", field: "name" },
//     { title: "HSN/SAC", field: "hsn_sac" },
//     { title: "Price", field: "selling_price" },
//     { title: "Tax %", field: "tax" },
//     { title: "Tax Amount", field: "tax_amount" },
//     { title: "Amount", field: "amount" },

// ]