import React from 'react'

export const pendingPaymentLabourColumn = [
    { title: "Labour Description", field: "name" },
    { title: "Quantity", field: "quantity" },
    { title: "HSN/SAC", field: "hsn_sac" },
    { title: "Price", field: "selling_price" },
    { title: "Tax %", field: "tax" },
    { title: "Tax Amount", field: "tax_amount" },
    { title: "Amount", field: "amount" },
]

export default pendingPaymentLabourColumn