import React from 'react'

const AddCustomerVehicleColumn = [
    { title: "Vehicle Number", field: "vehicle_number" },
    { title: "Vehicle Type", field: "vehicle_type" },
    { title: "Brand", field: "brand" },
    { title: "Model", field: "model" },
    { title: "Engine Customization", field: "customization" },
    { title: "Fuel Type", field: "fuel_type" }
]


export default AddCustomerVehicleColumn