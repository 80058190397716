import { Box } from '@mui/material'
import MoreActionDialog from 'components/common/Dialog/MoreActionDialog'

export const columnAdminFuelType = [
    { title: "Fuel Id", field: "fuel_id" },
    { title: "Fuel Name", field: "fuel_name" },
    // {
    //     title: 'Action', render: (rowData) =>
    //         <Box display='flex'>
    //             <MoreActionDialog
    //                 rowData={rowData}
    //             />
    //         </Box>
    // }
]