import { Box, Button, Card, Dialog, DialogContent, Grid, ThemeProvider, Typography } from "@mui/material";
import ServiceProviderDashboardIconCards from "components/common/Cards/HomePageCards/ServiceProviderDashboardIconCards";
import SpLatestActivityCard from "components/common/Cards/HomePageCards/SpLatestActivityCard";
import SpQuickPayment from "components/common/Cards/HomePageCards/SpQuickPayment";
import SpRating from "components/common/Cards/HomePageCards/SpRating";
import SpRevenueSingleCard from "components/common/Cards/HomePageCards/spRevenueSingleCard";
import { useMobileResponsive } from "hooks/useMobileResponsive";
import ApexCharts from "pages/serviceProvider/Home/Components/charts";
import serviceProviderHomeTheme from "pages/serviceProvider/Home/Components/theme";
import createJobCardIcon from 'assets/img/serviceProviderDashboard/createJobCardIcon.png'
import workInprogressIcon from 'assets/img/serviceProviderDashboard/workInprogressIcon.png'
import createInvoiceIcon from 'assets/img/serviceProviderDashboard/createInvoiceIcon.png'
import serviceDueIcon from 'assets/img/serviceProviderDashboard/serviceDueIcon.png'
import pendingServicesIcon from 'assets/img/serviceProviderDashboard/pendingServicesIcon.png'
import totalCustomersIcon from 'assets/img/serviceProviderDashboard/totalCustomersIcon.png'
import SpRevenueSplitCard from "components/common/Cards/HomePageCards/spRevenueSplitCard";
// import ModelAdminDialog from "../VehicleSettings/Models/Components/DialogModels";
import { useState } from "react";
import DialogWrapper from "components/common/Dialog/DialogWrapper";
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
// import AddManufacturerDialog from "../VehicleSettings/Manufacturer/Components/DialogAddManufacturer";
// import DialogFuelTypeAdmin from "../VehicleSettings/FuelType/Components/DialogFuelTypeAdmin";
import HandymanIcon from '@mui/icons-material/Handyman';
import { useNavigate } from "react-router-dom";
import { useCustomerContext } from "hooks/useCustomContext";
import URL from "url/apiURL";
import { useFetch } from "hooks/useFetch";
import UnderLine from "components/common/Underline";
// import './index.scss'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AddCustomerAppointmentDialog from "../Appointment/Components/AddCustomerAppointmentDialog";
import AddCustomerVehicleDialog from "../Vehicle/Components/AddCustomerVehicleDialog";
import EventNoteIcon from '@mui/icons-material/EventNote';
const { getStatistics } = URL.CUSTOMER.HOME

const CustomerDashboard = (props) => {
  const {isMobile} = useMobileResponsive()
  const {data:statistics} = useFetch(`${getStatistics}?customer_id=${localStorage.getItem('customer_id')}`)
  const { onChange, subItemOnChange } = useCustomerContext()
  const navigate = useNavigate()

//   const statistics = {
//     "error": false,
//     "result": {
//         "customerVehicleCount": "15",
//         "appointmentsCount": "20",
//         "servicesCompletedCount": "1",
//         "appointmentApprovedCount": "11",
//         "appointmentPendingCount": "6",
//         "rejectedCancelledAppointmentCount": "3"
//     }
// }

return (
  <>
      <ThemeProvider theme={serviceProviderHomeTheme}>
          <Box sx={{ backgroundImage: "linear-gradient(to bottom, rgb(233,56,72) , rgb(119,53,98))" }} className='total-usage'>
              {!isMobile &&
                  <Box sx={{ display: "flex", justifyContent: "center", color: "white", fontWeight: "700" }}>
                      <Typography fontSize={"45.05px"}>Hi {localStorage.getItem('profile_name')}!! Welcome to Avah car services</Typography>
                  </Box>
              }
              <Box className='shortcut-container'>
                  <DialogWrapper title={'Add New Vehicle'} cardIcon={{ img: <DriveEtaIcon />, text: 'Add New Vehicle' }}>
                    <AddCustomerVehicleDialog/>
                  </DialogWrapper>
                  <Box>
                      <Button className='card-icon-button' variant='contained' color='whiteBackground' onClick={() => { navigate('/customer/profile'); onChange(2) }}>
                          <Box className='container'>
                              <Box className='image'>
                                <AccountCircleIcon/>
                              </Box>
                              <Box className='text'>
                                Update Profile
                              </Box>
                          </Box>
                      </Button>
                  </Box>
                  <Box>
                      <Button className='card-icon-button' variant='contained' color='whiteBackground' onClick={() => { navigate('/customer/appointment/approvedAppointment'); onChange(4) }}>
                          <Box className='container'>
                              <Box className='image'>
                                <EventNoteIcon className="purple"/>
                                  {/* <img src={LocalGasStationIcon}></img> */}
                              </Box>
                              <Box className='text'>
                                Appointment List
                              </Box>
                          </Box>
                      </Button>
                  </Box>
                  <DialogWrapper title={'Create Appointment'} cardIcon={{ img: <EventAvailableIcon />, text: 'Create Appointment' }}>
                    <AddCustomerAppointmentDialog/>
                  </DialogWrapper>
              </Box>
          </Box>
          <Box m={3}>
              <Typography mb={1} variant='h5'>STATISTICS<UnderLine /></Typography>
              <Box className='statistics-main-container'>
                  <Box className='statistics-container'>
                      <Box className='left'>
                          <CheckCircleIcon className="green"/>
                      </Box>
                      <Box className='right'>
                          <Box className='count'>{statistics?.result?.appointmentApprovedCount || 'N/A'}</Box>
                          <Box className='text'>Approved Appointment</Box>
                      </Box>
                  </Box>
                  <Box className='statistics-container'>
                      <Box className='left'>
                          <CancelIcon className="red"/>
                      </Box>
                      <Box className='right'>
                          <Box className='count'>{statistics?.result?.rejectedCancelledAppointmentCount || 'N/A'}</Box>
                          <Box className='text'>Rejected Appointment</Box>
                      </Box>
                  </Box>
                  <Box className='statistics-container'>
                      <Box className='left'>
                          <ErrorIcon className="orange"/>
                      </Box>
                      <Box className='right'>
                          <Box className='count'>{statistics?.result?.appointmentPendingCount || 'N/A'}</Box>
                          <Box className='text'>Pending Appointment</Box>
                      </Box>
                  </Box>
              </Box>
              <Box className='counts-main-container'>
                  <Box className='custService-container'>
                      <Box className='customer'>
                          <Box className='left'>
                              <DirectionsCarIcon className="purple"/>
                          </Box>
                          <Box className='right'>
                              <Box className='count'>{statistics?.result?.customerVehicleCount || 'N/A'}</Box>
                              <Box className='text'>Total Vehicle</Box>
                          </Box>
                      </Box>
                      <Box className='service-provider'>
                          <Box className='right'>
                              <Box className='count'>{statistics?.result?.appointmentsCount || 'N/A'}</Box>
                              <Box className='text'>Total Appointments</Box>
                          </Box>
                          <Box className='left'>
                              <EventAvailableIcon className="purple"/>
                          </Box>
                      </Box>

                  </Box>
                  <Box className='approved-rej-container'>
                      <Box className='active'>
                          <Box className='left'>
                              <CheckCircleIcon className="green"/>
                          </Box>
                          <Box className='right'>
                              <Box className='count'>{statistics?.result?.servicesCompletedCount || 'N/A'}</Box>
                              <Box className='text'>Service Completed</Box>
                          </Box>
                      </Box>
                      {/* <Box className='rejected'>
                          <Box className='right'>
                              <Box className='count'>{statistics?.data?.getInactiveServiceProviderCount || 'N/A'}</Box>
                              <Box className='text'>Inactive Service Provider</Box>
                          </Box>
                          <Box className='left'>
                              <CancelIcon className="red"/>
                          </Box>
                      </Box> */}
                  </Box>

              </Box>
          </Box>
      </ThemeProvider>
  </>

);
};

export default CustomerDashboard;