import { Box } from "@mui/material";
import ActionDialog from "components/common/Dialog/ActionDialog";
import MoreActionDialog from "components/common/Dialog/MoreActionDialog";
import URL from "url/apiURL"
import EditFieldsDialog from "./EditFieldsDialog";

const {deleteEmployee} = URL.SERVICE_PROVIDER.USERS.EMPLOYEES
export const createEmployeeColumn = [
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Mobile", field: "mobile" },
    // { title: "Gender", field: "gender" },
    { title: "Role", field: "role" },
    { title: "Status", field: "status" },
    { title: "Action", render: (rowData)=>
        (<Box>
            <MoreActionDialog rowData={rowData}/>
            <EditFieldsDialog rowData={rowData}/>
            {/* <ActionDialog
                editEmployee
                url={deleteSpare}
                payload={{'spare_id':rowData?.spare_id}} 
                rowData={ rowData}
            /> */}
            <ActionDialog
                deleteEmployee
                url={deleteEmployee}
                payload={{'emp_id':rowData?.emp_id}} 
                rowData={ rowData}
            />

        </Box>)
    }
]