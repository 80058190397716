import { createTheme } from "@mui/material";

export const globalAppTheme = createTheme({
    palette:{
      addUser:{
        main:'#000000',
        contrastText:"#ffffff"
      },
      options:{
        main:'#ad4970',
        contrastText:"#ffffff"
  
      },
      darkerpink:{
        main:'#91365d',
        contrastText:"#ffffff"
  
      },
      whiteBackground:{
        main:'#ffffff',
        contrastText:'#000000',
        dark:"#e0e0e0"
      },
      greyButton:{
        main:'#e2e8f0',
        contrastText:'#000000',

      },
      black:{
        main:'#000000',
        contrastText:'#ffffff',

      },
      silver:{
        main: '#acb8c8',
        contrastText:'#ffffff',
      }
    },
  })